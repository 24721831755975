import { Button } from '@/Components/ui/button';
import { navigate, viewImage } from '@/Hooks/Functions';
import Loader from '@/Modules/Loader';
import { useAiPromptResponse } from '@/Pages/Prompting/utils';
import video1 from '@assets/videos/rmp-5.mp4';
import { useCallback, useMemo } from 'react';

export const DefaultBlock = () => {
    const videos = [
        { src: video1 },
        { src: video1 },
        { src: video1 },
        { src: video1 },
    ]

    return <div id="highlight-pending-image" className="grid relative grid-cols-2 lg:max-w-[500px] md:max-w-[400px] items-stretch gap-8">
        {videos.map((video, index) => <div data-aos="fade-up" key={index} className=" col-span-1 z-[2] md:min-h-[200px] min-h-[100px] aspect-square border-2 aspect-square flex flex-col justify-center items-center border-2 bg-white p-4 rounded-2xl shadow-xl">
            <div className='relative aspect-square md:min-h-[200px] min-h-[100px]'>
                <video autoPlay loop muted src={video.src} className="absolute top-0 left-0 w-full h-full object-cover" />
            </div>
        </div>)}
    </div>
}


export const ResultBlock = () => {

    const { response, setSelected } = useAiPromptResponse();

    const images = useMemo(() => response?.images || [], [response])

    const handleChoose = useCallback((image) => {
        setSelected({
            url: image.url,
            id: image.uuid,
            svg: false,
            type: 'ai-prompts'
        });
        navigate(route('designer.via-design-phase', { via: 'prompts', uuid: image.uuid, }))
    }, []);

    return <div id="highlight-pending-image" className="grid  grid-cols-2 lg:max-w-[500px] md:max-w-[400px] items-stretch gap-8">

        {Boolean(response && images.length === 0) && [1, 2, 3, 4].map(i => <Loader key={i} className=" col-span-1 rounded-2xl bg-white text-primary md:h-[200px] h-[100px] aspect-square" text="Generating image ..." />)}

        {Boolean(response && images.length > 0) && images.map((image) => <div data-aos="fade-up" key={image.id} className="col-span-1 bg-white md:min-h-[200px] min-h-[100px] aspect-square border-2 aspect-square flex flex-col justify-center items-center border-2 bg-white p-4 rounded-2xl shadow-xl">
            <div className='relative aspect-square md:min-h-[200px] min-h-[100px]'>
                <img src={image.url_inline} className="absolute top-0 left-0 w-full h-full object-cover" />
                <div className='flex w-full h-full absolute top-0 gap-2 left-0 bg-black/50 opacity-0 hover:opacity-100 transition-opacity duration-300 flex-col justify-center items-center'>
                    <Button onClick={() => viewImage(image?.url_inline)} className="rounded-full w-1/2" variant="theme-outline" size="xs">View</Button>
                    <Button onClick={() => handleChoose(image)} className="rounded-full w-1/2" variant="theme" size="xs">Choose</Button>
                </div>
            </div>
        </div>)}
    </div>
}
